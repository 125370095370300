<template>
  <v-container>
    <div id="resetpassword" style="height: 100%">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="5" lg="4">
          <v-card elevation="12" style="margin-top: 5vh">
            <v-progress-linear
              :active="!loaded"
              indeterminate
              absolute
              top
            ></v-progress-linear>
            <v-card-text style="padding: 40px 40px 40px 40px">
              <div class="text-center mb-4">
                <img
                  src="../assets/logo.png"
                  alt="Bryggeriforeningen"
                  style="width: 240px; height: auto"
                />
              </div>

              <div v-if="user && !userUpdated && loaded">
                <div class="mb-6">
                  Opret ny adgangskode for denne konto<br />
                  <strong>{{ user.email }}</strong
                  ><br /><br />
                  Adgangskoden skal bestå af følgende:
                  <ul class="mt-3 mb-3">
                    <li>Minimumslængde på 8 karakterer</li>
                  </ul>
                  Adgangskoden skal have 3 ud af 4:
                  <ul class="mt-3">
                    <li>Mindst et stort bogstav [A-Z]</li>
                    <li>Mindst et lille bogstav [a-a]</li>
                    <li>Mindst et tal [0-9]</li>
                    <li>Mindst en specialkarakter [-$+# etc.]</li>
                  </ul>
                </div>
                <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
                  <v-text-field
                    label="New password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    required
                    :rules="[requiredRule, lengthEightRule, passwordRule]"
                    v-model="form.password"
                    @input="onFormChange"
                  ></v-text-field>

                  <v-text-field
                    label="Repeat new password"
                    placeholder="Password"
                    name="repeatpassword"
                    class="mb-6"
                    type="password"
                    required
                    :rules="[requiredRule, passwordSameRule]"
                    v-model="form.repeatPassword"
                    @input="onFormChange"
                  ></v-text-field>

                  <v-btn type="submit" large min-width="200" block
                    >Gem adgangskode</v-btn
                  >
                </v-form>
              </div>

              <div v-if="!user && loaded">
                <div class="mb-6">
                  <div class="title text-center mb-5">
                    Linket er ikke længere gyldigt
                  </div>
                  Linket er gyldigt i 6 timer fra du har modtaget emailen<br /><br />

                  Brug venligst
                  <router-link to="/forgotpassword"
                    ><a>glemt adgangskode formularen</a></router-link
                  >
                  for at prøve igen.
                </div>
                <v-btn
                  class="primary"
                  type="button"
                  large
                  min-width="200"
                  block
                  @click="onBackToLoginClick"
                  >Tilbage til log ind</v-btn
                >
              </div>

              <div v-if="user && userUpdated && loaded">
                <div class="mb-6">
                  <div class="title text-center mb-5">
                    Adgangskode opdateret
                  </div>
                  Du kan nu logge ind med din nye adgangskode<br /><br />
                </div>
                <v-btn
                  class="primary"
                  type="button"
                  large
                  min-width="200"
                  block
                  @click="onBackToLoginClick"
                  >Tilbage til log ind</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import api from "../api/api.js";
import {
  required,
  email,
  lengthEight,
  hasUpper,
  hasLower,
  hasNumber,
  hasSpecialCharacter,
} from "../utils/FormRules.js";

export default {
  name: "ForgotPassword",
  components: {},
  data: () => ({
    valid: false,
    loaded: false,
    user: null,
    userUpdated: false,
    form: {
      password: "",
      repeatPassword: "",
    },
    requiredRule: required,
    emailRule: email,
    lengthEightRule: lengthEight,
  }),
  mounted() {
    this.loadUser();
  },
  methods: {
    onFormChange() {
      this.$refs.form.validate();
    },
    passwordRule(v) {
      // Test rules individually
      const hasValidUpper = hasUpper(v);
      const hasValidLower = hasLower(v);
      const hasValidNumber = hasNumber(v);
      const hasValidSpecial = hasSpecialCharacter(v);

      // Collects rule results
      const results = [
        hasValidUpper,
        hasValidLower,
        hasValidNumber,
        hasValidSpecial,
      ];

      // Filter out invalid rule results
      const invalids = results.filter((result) => result !== true);

      // Allow for one individual rule to be invalid, for the main rule to be valid
      return invalids.length <= 1
        ? true
        : `Must follow ${invalids.length - 1} more of the rules above`;
    },
    passwordSameRule(v) {
      return v === this.form.password || "Repeat password must match password";
    },
    async onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        try {
          const result = await api.resetPassword(
            this.$route.query.email,
            this.$route.query.token,
            this.form.password
          );

          this.user = result.data;
          this.userUpdated = true;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onBackToLoginClick() {
      this.$router.replace("/login");
    },
    async loadUser() {
      try {
        const result = await api.passwordResetUser(
          this.$route.query.email,
          this.$route.query.token
        );
        this.user = result.data;
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
      }
    },
    ...mapActions(["forgotPassword"]),
  },
  computed: {
    ...mapState(["forgotPasswordCompleted"]),
  },
};
</script>
